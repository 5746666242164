import * as React from "react";

import * as styles from "./index.module.scss";
import {IMPORT_RETURN_SHIPPING_LIST} from "../../../constants/Features";

const ImportReturnShippingList = () => {
    return (
        <div className={styles.actionsWrapper}>
            {IMPORT_RETURN_SHIPPING_LIST.map((item)=> {
               return <div className={styles.actionContainer}>
                    <h3 className={styles.actionTitle}>{item.title}</h3>
                   <p className={styles.actionContent}>{item.content}</p>
                </div>
            })}
        </div>
    );
};

export default ImportReturnShippingList;