import * as React from "react";

import * as styles from "./index.module.scss";
import {INVENTORY_LIST} from "../../../constants/Features";

const InventoryActionsList = () => {
    return (
        <div className={styles.actionsWrapper}>
            {INVENTORY_LIST.map((item)=> {
               return <div className={styles.actionContainer}>
                    <h3 className={styles.actionTitle}>{item.title}</h3>
                   <p className={styles.actionContent}>{item.content}</p>
                </div>
            })}
        </div>
    );
};

export default InventoryActionsList;