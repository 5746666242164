import * as React from "react";

import * as styles from "./index.module.scss";

import SectionLayout from "../../../components/SectionLayout";
import BackgroundImage from "../../../components/BackgroundImage";
import featuresBanner from "../../../images/FeaturesImages/features_banner@3x.jpg";
import featuresBanner768 from "../../../images/FeaturesImages/features_banner@2x.jpg";
import featuresBanner320 from "../../../images/FeaturesImages/features_banner.jpg";
import Svg from "../../../images/faq-banner.inline.svg";

const FeaturesSection = () => {
  return (
    <SectionLayout className={styles.sectionWrapper}>
      <BackgroundImage
          backgroundImageDesktop={featuresBanner}
          backgroundImageTablet={featuresBanner768}
          backgroundImageMobile={featuresBanner320}
          imageStyle={styles.backgroundImage}
          Svg={Svg}
      >
        <div className={styles.backgroundImageChildren}>
          <span>Features</span>
        </div>
      </BackgroundImage>
    </SectionLayout>
  );
};

export default FeaturesSection;
