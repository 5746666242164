import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import FeaturesSection from "../../sections/Features/FeaturesSection";
import FeaturesList from "../../sections/Features/FeaturesList";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <FeaturesSection />
      <FeaturesList />
      <Footer />
    </>
  );
};

const Features = () => {
  return (
    <>
      <Header />
      <PageLayout title="Features">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default Features;
