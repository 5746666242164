// extracted by mini-css-extract-plugin
export var featureImageContainer = "index-module--featureImageContainer--BA0vo";
export var featureVideoContainer = "index-module--featureVideoContainer--EF4JC";
export var featureWrapperTextLeft = "index-module--feature-wrapper-text-left--zbMk2";
export var featureWrapperTextRight = "index-module--feature-wrapper-text-right--EMD6+";
export var imageWrapperTextLeft = "index-module--image-wrapper-text-left--cONyd";
export var imageWrapperTextRight = "index-module--image-wrapper-text-right--Kfmn4";
export var img = "index-module--img--YAFf3";
export var text = "index-module--text--JTkoh";
export var textWrapper = "index-module--text-wrapper--BWsDX";
export var title = "index-module--title--Ii+Hv";
export var video = "index-module--video--1uj-o";