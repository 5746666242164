import * as React from "react";

import * as styles from "./index.module.scss";

import featureListingSecondaryImg from "../../../images/FeaturesImages/listing_secondary_img.png"
import featureMergeSecondaryImg from "../../../images/FeaturesImages/merge_secondary_img.png"
import featureUpdateSoldSecondaryImg from "../../../images/FeaturesImages/update_sold_secondary_img.png"
import featureAnalytics1024 from "../../../images/FeaturesImages/feature_analytics_1024.png"
import featureAnalyticsSecondaryImg from "../../../images/FeaturesImages/analytics_secondary_img.png"
import featureBookkeeping1024 from "../../../images/FeaturesImages/feature_bookkeping_1024.png"
import featureBookkeepingSecondaryImg from "../../../images/FeaturesImages/bookeeping_secondary_img.png"
import featureEmployeeSecondaryImg from "../../../images/FeaturesImages/employee_secondary_img.png"

import invitationVideo from "../../../../static/media/invitationVideo.webm"
import mergeVideo from "../../../../static/media/mergeVideo.webm"
import updateVideo from "../../../../static/media/updateVideo.webm"
import reviseVideo from "../../../../static/media/reviseVideo.webm"
import invitationVideoMp4 from "../../../../static/media/invitationVideo.mp4"
import mergeVideoMp4 from "../../../../static/media/mergeVideo.mp4"
import updateVideoMp4 from "../../../../static/media/updateVideo.mp4"
import reviseVideoMp4 from "../../../../static/media/reviseVideo.mp4"

import SectionLayout from "../../../components/SectionLayout";
import FeatureCard from "../../../components/FeatureCard";
import InventoryActionsList from "../InventoryActionsList";
import ImportReturnShippingList from "../ImportReturnShippingList";
import FeatureNumberedList from "../FeaturesNumberedList";

const FeaturesList = () => {
    return (
        <SectionLayout className={styles.sectionWrapper}>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    videoUrl={reviseVideo}
                    videoUrlMp4={reviseVideoMp4}
                    imageSecondaryUrl={featureListingSecondaryImg}
                    imageSecondaryStyle={styles.listingSecondary}
                    title="Revise Your Listings"
                    text="Want to drop prices? Update your titles? Change your SKU? You can do it all within Fearn! Simply make the changes you'd like within your listing on Fearn and click Revise. Your edits will be made to your listing on your chosen marketplace. Want to edit your listing amongst every platform it's crosslisted to? Use the Revise All button on the Basic form (Revise All in beta - coming soon)!"
                    textToRight={false}
                />
            </div>
            <InventoryActionsList/>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    videoUrl={mergeVideo}
                    videoUrlMp4={mergeVideoMp4}
                    imageSecondaryUrl={featureMergeSecondaryImg}
                    imageSecondaryStyle={styles.mergeSecondary}
                    title="Merge Imported Listings"
                    text="After importing your listings from various marketplaces, Fearn makes it easy to merge them together with our Merge feature. Simply choose the listing you'd like to merge & Fearn will generate similar imported listings from other marketplaces. Once you've found your match simply click Merge and voila! It’s as easy as that!"
                    textToRight={true}
                />
            </div>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    videoUrl={updateVideo}
                    videoUrlMp4={updateVideoMp4}
                    imageSecondaryUrl={featureUpdateSoldSecondaryImg}
                    imageSecondaryStyle={styles.updateSoldSecondary}
                    title="Update & Delist Sales"
                    text="Don't spend hours of your week updating an inventory spreadsheet and manually delisting sold items! Now with Fearn, you can do all that with one click of a button. By clicking Update, Fearn scans all marketplaces for any sales you’ve made, moves all sold inventory into the Sold tab, imports all sales related info (sales price, platform fees, etc) which automatically gets accounted for in your bookkeeping & analytics, and delists sold items from all other marketplaces they were crosslisted on. It's a HUGE time saver!"
                    textToRight={false}
                />
            </div>
            <ImportReturnShippingList/>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    imageUrl={featureAnalytics1024}
                    imageSecondaryUrl={featureAnalyticsSecondaryImg}
                    imageSecondaryStyle={styles.analyticsSecondary}
                    title="Analytics"
                    text="Almost every platform has its own analytics–but what if you want analytics for your reselling business as a whole? Fearn is designed just for that! Our favorite feature? Set a monthly goal and track your performance. But also, analyze your overall sales, your sales per platform, what brands & categories are doing well, and what you currently have in stock."
                    textToRight={true}
                />
            </div>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    imageUrl={featureBookkeeping1024}
                    imageSecondaryUrl={featureBookkeepingSecondaryImg}
                    imageSecondaryStyle={styles.bookkeepingSecondary}
                    title="Bookkeeping"
                    text="When you’re running a busy reselling business, keeping up with bookkeeping can be overwhelming. Fearn was built to relieve the stress of bookkeeping with easy-to-use expense tracking and mileage tracking, plus an income worksheet to help assess your business’ profitability month over month."
                    textToRight={false}
                />
            </div>
            <div className={styles.featureWrapper}>
                <FeatureCard
                    videoUrl={invitationVideo}
                    videoUrlMp4={invitationVideoMp4}
                    imageSecondaryUrl={featureEmployeeSecondaryImg}
                    imageSecondaryStyle={styles.employeeSecondary}
                    title="Invite Employees"
                    text="Many resellers have virtual assistants and employees to help expedite growth, but since Fearn is a fully integrated software, we wanted to make sure that your employees won’t have access to your numbers. So, we’ve created an employee invite system (included in both the Business & Unlimited Plan) that allows your employees to set up their own joint account, but hides Bookkeeping and Analytics."
                    textToRight={true}
                />
            </div>
            <FeatureNumberedList/>
        </SectionLayout>
    );
};

export default FeaturesList;
