import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as styles from "./index.module.scss";

const FeatureCard = ({imageUrl, videoUrl, videoUrlMp4, imageSecondaryUrl, backgroundImageMobile, backgroundImageTablet, backgroundImageDesktop, imageSecondaryStyle, title, text, textToRight = true}) => {
    return (
        <div
            className={classnames({
                [styles.featureWrapperTextRight]: textToRight,
                [styles.featureWrapperTextLeft]: !textToRight,
            })}
        >
            <div
                className={classnames({
                    [styles.imageWrapperTextRight]: textToRight,
                    [styles.imageWrapperTextLeft]: !textToRight,
                })}
            >
                {videoUrl && <div className={styles.featureVideoContainer}>
                    <video className={styles.video} autoPlay muted loop>

                        <source src={videoUrl}
                                type="video/webm"/>
                        <source src={videoUrlMp4}
                                type="video/mp4"/>


                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <img src={imageSecondaryUrl} className={imageSecondaryStyle}/>
                </div>}
                {imageUrl && <div className={styles.featureImageContainer}>
                    <img src={imageUrl} className={styles.img} alt="Feature"/>
                    <img src={imageSecondaryUrl} className={imageSecondaryStyle}/>
                </div>}

            </div>
            <div className={styles.textWrapper}>
                <span className={styles.title}>{title}</span>
                <span className={styles.text}>{text}</span>
            </div>
        </div>
    );
};

FeatureCard.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    textToRight: PropTypes.bool,
};

export default FeatureCard;
