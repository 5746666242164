import * as React from "react";

import * as styles from "./index.module.scss";
import {NUMBERED_LIST} from "../../../constants/Features";

const FeatureNumberedList = () => {
    return (
        <div className={styles.actionsWrapper}>
            {NUMBERED_LIST.map((item)=> {
               return <div className={styles.actionContainer}>
                   <p className={styles.actionNumber}>{item.number}</p>
                    <h3 className={styles.actionTitle}>{item.title}</h3>
                   <p className={styles.actionContent}>{item.content}</p>
                </div>
            })}
        </div>
    );
};

export default FeatureNumberedList;