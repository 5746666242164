import * as React from "react";

export const INVENTORY_LIST = [
    {
        title: "Import",
        content: "Easily onboard & import all of your listings at once. Or select the ones you'd like to import.",
    },
    {
        title: "Crosslist",
        content: "Earn more with Fearn by taking advantage of our easy-to-use crosslisting system.",
    },
    {
        title: "Relist",
        content: "Listings getting stale? Want to boost them in search? Relist your items with a click of a button!",
    },
    {
        title: "Delist",
        content: "Never get caught selling one item on two platforms. You can delist each listing individually or use Update.",
    },
]

export const IMPORT_RETURN_SHIPPING_LIST = [
    {
        title: "Import Sales",
        content: "Import older sales on Fearn for up-to-date bookkeeping & analytics.",
    },
    {
        title: "Returns",
        content: "Easily manage and track your returns from all marketplaces in one place!",
    },
    {
        title: "Shipping",
        content: "Streamline shipping with a centralized picking list and shipping list while tracking your associated costs.",
    },
]

export const NUMBERED_LIST = [
    {
        number: "01",
        title: "Easy Onboarding",
        content: "Simply add your employee’s email address & they’ll receive an invite to create a joint Fearn account.",
    },
    {
        number: "02",
        title: "Task Management",
        content: "Fearn also includes a task management system! Create to-do lists, assign tasks, and leave comments for your employees.",
    },
]